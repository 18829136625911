<template>
    <div :key="page_key">
        <LiefengModal title="标签管理" :value="display" :fullscreen="false" :width="600" :height="600" @input="onChangeModal(false)">
            <template v-slot:contentarea>
                <div class="container">
                    <div class="content">
                        <Form :model="formData" :label-colon="true" :label-width="100" :rules="formDataValidate" ref="form">
                            <Transfer v-if="labelList" class="label-community-transfer-box" :data="labelList" :target-keys="labelTargetKeys" :list-style="listStyle" :render-format="transferRender" :titles="['源标签', '目标标签']" filterable @on-change="onChangeLabel"></Transfer>
                        </Form>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right:10px" @click="onChangeModal(false)">取消</Button>
                <Button type="primary" @click="submit">确定</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
import RequestPage from "../utils/page"

export default {
    components: { LiefengModal },

    data() {
        return {
            display: false,
            page_key: this.$core.randomString(),

            labelList: null,
            labelTargetKeys: [],

            listStyle: {
                width: "250px",
                height: "500px",
            },
        }
    },

    methods: {
        /**
         * 获取所有标签
         */
        getAllLabel() {
            return new Promise(resolve => {
                const page = new RequestPage(`/gateway/api/sycompany/pc/label/getLabelByPage?oemCode=${this.$core.getUserInfo().oemCode}&labelType=2,3`, {
                    onChange: res => {
                        if (page.isLoaded()) {
                            for (let i = 0; i < res.length; i++) {
                                const e = res[i]
                                e.key = e.labelCode
                            }

                            resolve(res)
                        } else {
                            page.load()
                        }
                    },
                })
            })
        },

        transferRender(item) {
            return item.labelCode + " - " + item.labelName
        },

        onChangeLabel(e) {
            this.labelTargetKeys = e
        },

        async open(codes = []) {
            if (!this.labelList) {
                this.labelList = await this.getAllLabel()
            }

            this.labelTargetKeys = codes
            this.onChangeModal(true)
        },

        /**
         * 提交事件
         */
        submit() {
            const keys = this.labelTargetKeys
            const labels = this.labelList
            const res = []

            for (let i = 0; i < labels.length; i++) {
                let l = labels[i]

                let idx = keys.indexOf(l.labelCode)

                if (idx > -1) {
                    res.push(l)
                }
            }

            this.$emit("on-submit", {
                label: "comp-label",
                value: {
                    keys: keys,
                    entitys: res,
                },
            })

            this.onChangeModal(false)
        },

        onChangeModal(flag) {
            // 改变弹窗显示状态
            this.display = flag
            // 如果为显示模式，刷新 Key
            if (flag) {
                this.page_key = this.$core.randomString()
            } else {
                // 父页面刷新列表
                this.$emit("resetBtn")
            }
        },
    },
}
</script>

<style lang="less" scoped>
.label-community-transfer-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 123px);
    /deep/.menu {
        height: 100%;
        .ivu-menu {
            height: 100%;
            width: 220px !important;
        }
    }
    .content {
        overflow: auto;
        padding-top: 20px;
        flex: 1;
        height: 100%;
    }
}

/deep/ .validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}

/deep/ #modal_contentarea {
    overflow: inherit !important;
}
</style>
