<template>
    <div class="form-list-page">
        <div class="item-box" v-for="(sub, sub_idx) in value" :key="sub.key_id">
            <div class="operating-box">
                <div class="button clear-button" @click="onChangeList('clear', sub_idx)">
                    <Icon type="md-close" color="#ff7878" />
                </div>
                <div class="button add-button" @click="onChangeList('append')">
                    <Icon type="md-add" />
                    <span>{{ addTitle }}</span>
                </div>
            </div>
            <slot name="formBox" :item="sub" :index="sub_idx"></slot>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "value",
        event: "change",
    },

    props: {
        value: {
            type: Array,
            default: () => [],
        },

        name: String,

        addTitle: {
            type: String,
            required: true,
        },
    },

    watch: {
        value: {
            handler(val, o) {
                if (val === o) return
                if (!val) val = []

                if (val.length <= 0) {
                    val.push({
                        key_id: this.$core.randomString(),
                    })
                }

                for (let i = 0; i < val.length; i++) {
                    const e = val[i]

                    if (!e.key_id) {
                        e.key_id = this.$core.randomString()
                    }
                }

                this.$emit("change", val)
            },
            immediate: true,
        },
    },

    methods: {
        /**
         * 列表变化事件
         */
        onChangeList(type, idx) {
            const value = this.value

            if (type === "append") {
                value.push({
                    key_id: this.$core.randomString(),
                })
            } else {
                value.splice(idx, 1)
            }

            this.$emit("change", value)
        },
    },
}
</script>

<style lang="less">
.form-list-page {
    width: 100%;

    > .item-box {
        position: relative;
        margin: 0 0 20px 0;
        padding: 10px 30px 10px 10px;
        border: 1px solid #e3e3e3;
        border-radius: 6px;

        .ivu-form-item:nth-last-child(2) {
            margin-bottom: 0;
        }

        > .operating-box {
            position: absolute;
            top: 0;
            right: -13px;
            bottom: 0;
            width: 26px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .button {
                position: relative;
                cursor: pointer;
                min-width: 26px;
                min-height: 26px;
                padding: 10px 0;
                background: #fff;
                border: 1px solid #e3e3e3;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                -webkit-transition-timing-function: ease;
                transition-timing-function: ease;

                &:hover {
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                }

                &.add-button {
                    display: none;
                    margin-top: 10px;
                }

                .img {
                    width: 70%;
                    height: 70%;
                }
            }
        }

        &:only-child {
            .operating-box {
                .add-button {
                    margin-top: 0;
                }

                .clear-button {
                    display: none;
                }
            }
        }

        &:last-child {
            .operating-box {
                .add-button {
                    display: flex;
                }
            }
        }
    }
}
</style>
