<template>
    <div>
        <comp-form
            ref="comp_form"
            :data-before="processData"
            :fullscreen="true"
            :submit-before="onSubmitBefore"
            class="jointly-comp-form"
            detail-api="/gateway/api/sycompany/pc/company/getCompanyInfoById"
            id-key="companyId"
            submit-api="/gateway/api/sycompany/pc/company/saveCompany"
            title="辖区共建"
            @on-close="onCloseForm"
            @on-submit="$emit('on-submit', $event)"
        >
            <template v-slot:form-box="data">
                <div class="container jointly-info-form">
                    <div class="menu">
                        <Menu :active-name="showMenu" theme="light" @on-select="activeMenu">
                            <MenuItem name="basicInfo">
                                <Icon type="ios-information-circle" />
                                基本信息
                            </MenuItem>
                            <MenuItem v-if="source === 'edit'" name="businessHours">
                                <Icon type="ios-information-circle" />
                                营业时间
                            </MenuItem>
                            <MenuItem v-if="source === 'edit'" name="settled">
                                <Icon type="ios-information-circle" />
                                入驻信息
                            </MenuItem>
                            <MenuItem v-if="source === 'edit'" v-show="online_service === '1'" name="intro">
                                <Icon type="ios-information-circle" />
                                简介
                            </MenuItem>
                            <MenuItem v-if="source === 'edit'" name="album">
                                <Icon type="ios-information-circle" />
                                相册
                            </MenuItem>
                        </Menu>
                    </div>
                    <div class="content">
                        <div v-show="showMenu == 'basicInfo'" style="padding: 10px 0">
                            <ElemFormItem width="120px" name="name" title="单位名称" required>
                                <Input v-model.trim="data.form.name" placeholder="请输入单位名称" style="width: 300px"></Input>
                            </ElemFormItem>
                            <ElemFormItem width="120px" name="nature" title="单位性质" required>
                                <Select v-model="data.form.nature" style="width: 300px">
                                    <Option value="1">个体</Option>
                                    <Option value="2">企业/公司/法人</Option>
                                    <Option value="3">国企/央企</Option>
                                    <Option value="4">社会组织/社会团体</Option>
                                    <Option value="5">机关/事业单位</Option>
                                    <Option value="6">其他</Option>
                                </Select>
                            </ElemFormItem>
                            <ElemFormItem width="120px" name="zoneCode" title="所属社区" required>
                                <elem-cascader :orgCode="4401" :value="data.form.community" :width="300" @changeCasader="onChangeCasader($event, data.form)"></elem-cascader>
                            </ElemFormItem>
                            <ElemFormItem width="120px" name="longitude" title="百度经纬度" required>
                                <Input placeholder="百度经纬度如：113.273441,23.145438" v-model.trim="data.form.longitude" :maxlength="40" style="width: 300px"></Input>
                            </ElemFormItem>
                            <ElemFormItem width="120px" title="选择经纬度">
                                <Button type="info" @click="openMap">选择经纬度</Button>
                            </ElemFormItem>
                             <ElemFormItem width="120px" name="addr" title="详细地址">
                                <Input placeholder="请填写详细地址" v-model.trim="data.form.addr" :maxlength="100" style="width: 300px"></Input>
                            </ElemFormItem>
                            <!-- <ElemFormItem width="120px" name="addr" title="联系地址" required>
                                <ElemMap
                                    :address="data.form.addr"
                                    :location="{ longitude: data.form.longitude, latitude: data.form.latitude }"
                                    @on-change="
                                        evt => {
                                            data.form.addr = evt.value.address
                                            data.form.longitude = evt.value.location.longitude
                                            data.form.latitude = evt.value.location.latitude
                                        }
                                    "
                                ></ElemMap>
                            </ElemFormItem> -->
                            <ElemFormItem width="120px" name="principal" title="管理人姓名">
                                <Input v-model.trim="data.form.principal" placeholder="请填写安全生产管理人姓名" style="width: 300px"></Input>
                            </ElemFormItem>
                            <ElemFormItem width="120px" name="mobile" title="联系电话">
                                <Input v-model.trim="data.form.mobile" placeholder="请填写联系电话" style="width: 300px" type="text"></Input>
                            </ElemFormItem>
                            <ElemFormItem width="120px" title="封面图片">
                                <elem-upload ref="elem_upload_cover_image" :value="data.form.cover_image" @on-change="data.form.cover_image = $event.value"></elem-upload>
                            </ElemFormItem>
                            <ElemFormItem width="120px" title="介绍图片">
                                <elem-upload
                                    ref="elem_upload_introduction_image"
                                    :multiple="true"
                                    :value="data.form.introduction_image"
                                    @on-change="data.form.introduction_image = $event.value"
                                ></elem-upload>
                            </ElemFormItem>
                            <ElemFormItem width="120px" title="基本介绍">
                                <Input v-model.trim="data.form.remark" :rows="4" placeholder="请填写基本介绍" style="width: 300px" type="textarea"></Input>
                            </ElemFormItem>
                        </div>

                        <div v-if="source === 'edit'" v-show="showMenu == 'businessHours'" style="padding: 10px 0">
                            <elem-form-list v-model="data.form.businessTimeRos">
                                <template v-slot:formBox="data">
                                    <ElemFormItem width="120px" title="星期">
                                        <Select v-model="data.item.rule" multiple style="width: 300px">
                                            <Option v-for="(item, idx) in ['一', '二', '三', '四', '五', '六', '日']" :key="idx" :value="idx + 1 + ''">周{{ item }}</Option>
                                        </Select>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="开始营业时间">
                                        <TimePicker v-model="data.item.startTime" confirm format="HH:mm" placeholder="选择开始营业时间" style="width: 300px"></TimePicker>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="结束营业时间">
                                        <TimePicker v-model="data.item.endTime" confirm format="HH:mm" placeholder="选择结束营业时间" style="width: 300px"></TimePicker>
                                    </ElemFormItem>
                                </template>
                            </elem-form-list>
                        </div>

                        <div v-if="source === 'edit'" v-show="showMenu == 'settled'" style="padding: 10px 0">
                            <ElemFormItem width="120px" title="提供线上服务" required>
                                <RadioGroup v-model="online_service">
                                    <Radio label="0">否</Radio>
                                    <Radio label="1">是</Radio>
                                </RadioGroup>
                            </ElemFormItem>
                            <div v-if="online_service === '1'" required>
                                <ElemFormItem width="120px" name="type" title="所属类型">
                                    <Select filterable v-if="type_list" v-model="data.form.type" style="width: 300px">
                                        <Option v-for="(item, idx) in type_list" :key="idx" :value="item.id">{{ item.labelName }}</Option>
                                    </Select>
                                </ElemFormItem>
                                <ElemFormItem width="120px" name="companyLabels" title="标签设置" required>
                                    <Input v-model.trim="data.form.companyLabelNames" placeholder="请选择标签" readonly style="width: 300px" type="textarea"></Input>
                                    <Button style="margin-left: 10px" type="primary" @click="onOpenSelectLabel()">选择标签</Button>
                                </ElemFormItem>
                                <ElemFormItem width="120px" title="提供预约" required>
                                    <RadioGroup v-model="data.form.appointmentStatus">
                                        <Radio label="0">否</Radio>
                                        <Radio label="1">是</Radio>
                                    </RadioGroup>
                                </ElemFormItem>
                                <ElemFormItem width="120px" title="是否申请商家" required>
                                    <RadioGroup v-model="shopStatus">
                                        <Radio label="0">否</Radio>
                                        <Radio label="1">是</Radio>
                                    </RadioGroup>
                                </ElemFormItem>
                                <div v-if="shopStatus === '1'">
                                    <ElemFormItem width="120px" title="支付平台商户ID">
                                        <Input v-model.trim="data.form.merchantId" placeholder="请填写支付平台商户ID" style="width: 300px"></Input>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="电子邮件">
                                        <Input v-model.trim="data.form.email" placeholder="请填写电子邮件" style="width: 300px"></Input>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="idNum" title="身份证号" required>
                                        <Input v-model.trim="data.form.idNum" placeholder="请填写身份证号" style="width: 300px"></Input>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="手持身份证照片">
                                        <elem-upload ref="elem_upload_id_card_handheld" :value="data.form.id_card_handheld" @on-change="data.form.id_card_handheld = $event.value"></elem-upload>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="id_card_front" title="身份证正面" required>
                                        <elem-upload ref="elem_upload_id_card_front" :value="data.form.id_card_front" @on-change="data.form.id_card_front = $event.value"></elem-upload>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="id_card_reverse" title="身份证反面" required>
                                        <elem-upload ref="elem_upload_id_card_reverse" :value="data.form.id_card_reverse" @on-change="data.form.id_card_reverse = $event.value"></elem-upload>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="营业执照">
                                        <elem-upload ref="elem_upload_business_license" :value="data.form.business_license" @on-change="data.form.business_license = $event.value"></elem-upload>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="诚信经营承诺函/服务协议">
                                        <elem-upload ref="elem_upload_letter_commitment" :value="data.form.letter_commitment" @on-change="data.form.letter_commitment = $event.value"></elem-upload>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="银行开户名">
                                        <Input v-model.trim="data.form.bankAdminName" placeholder="请填写银行开户名" style="width: 300px"></Input>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="个人银行账号">
                                        <Input v-model.trim="data.form.bankAccount" placeholder="请填写个人银行账号" style="width: 300px"></Input>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="开户支行名称">
                                        <Input v-model.trim="data.form.bankBranchName" placeholder="请填写开户银行支行名称" style="width: 300px"></Input>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="支行联行号">
                                        <Input v-model.trim="data.form.bankBranchNum" placeholder="请填写支行联行号" style="width: 300px"></Input>
                                    </ElemFormItem>

                                    <div class="title-box">审核信息</div>
                                    <ElemFormItem width="120px" name="settlementType" title="结算类型" required>
                                        <Select v-model="data.form.settlementType" style="width: 300px">
                                            <Option v-for="(item, idx) in settlementTypes" :key="idx" :value="item.dictKey">{{ item.dictValue }}</Option>
                                        </Select>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="platformUseFee" title="平台使用费用" required>
                                        <Input v-model.trim="data.form.platformUseFee" placeholder="请填写平台使用费用" style="width: 300px" type="number"></Input>
                                        <span style="margin-left: 10px">元</span>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="margin" title="商家保证金" required>
                                        <Input v-model.trim="data.form.margin" placeholder="请填写商家保证金" style="width: 300px" type="number"></Input>
                                        <span style="margin-left: 10px">元</span>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="dividedScale" title="分成比例" required>
                                        <Input v-model.trim="data.form.dividedScale" placeholder="请填写分成比例" style="width: 300px" type="number"></Input>
                                        <span style="margin-left: 10px">%</span>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="status" title="审核结果" required>
                                        <Select v-model="data.form.status" style="width: 300px">
                                            <Option value="1">未审核</Option>
                                            <Option value="2">审核通过</Option>
                                            <Option value="3">未通过</Option>
                                        </Select>
                                    </ElemFormItem>
                                </div>
                            </div>
                        </div>

                        <div v-show="showMenu == 'intro'" style="height: 100%; width: 100%; overflow: hidden">
                            <iframe :id="ueditor_id" name="ueditor" src="/mobileeditor/#/index?titlename=简介内容" style="width: 100%; height: 100%; border: 0px"></iframe>
                        </div>

                        <div v-show="showMenu == 'album'" style="padding: 10px 0">
                            <ElemFormItem width="80px" title="现场图片">
                                <ElemUploads ref="elem_upload_scene_photo" length="6" :value="data.form.scene_photo"></ElemUploads>
                            </ElemFormItem>
                            <ElemFormItem width="80px" title="视频">
                                <ElemUploads ref="elem_upload_video" :value="data.form.video"></ElemUploads>
                            </ElemFormItem>
                            <ElemFormItem width="80px" title="音频">
                                <ElemUploads ref="elem_upload_audio" :value="data.form.audio"></ElemUploads>
                            </ElemFormItem>
                        </div>
                    </div>
                </div>

                <LiefengModal class="map" :fullscreen="false" width="80%" title="选择经纬度" @input="mapFn" :value="mapStatus">
                    <template #contentarea style="width: 100%; height: 700px">
                        <Map
                            :showNum="showNum"
                            :localName="localName"
                            :center="center"
                            @clickMap="
                                evt => {
                                    data.form.longitude = evt.lng + ',' + evt.lat
                                }
                            "
                        ></Map>
                    </template>
                    <template #toolsbar>
                        <Button
                            type="info"
                            style="margin-right: 10px"
                            @click="
                                mapTip = true
                                mapFn(false)
                            "
                        >
                            取消
                        </Button>
                        <Button type="primary" @click="saveMap">确定</Button>
                    </template>
                </LiefengModal>
            </template>
        </comp-form>

        <comp-label ref="comp_label" @on-submit="onSubmitLabel"></comp-label>
    </div>
</template>

<script>
import CompLabel from "./comp-label.vue"
import ElemUpload from "../components/elem-upload.vue"
import ElemFormList from "../components/elem-form-list.vue"
import ElemFormItem from "../components/elem-form-item.vue"
import ElemCascader from "../components/elem-cascader.vue"
import ElemMap from "../components/ElemMap.vue"
import CompForm from "../components/CompForm.vue"
import ElemUploads from "../../customform/components/ElemUpload.vue"

import Headway from "../utils/headway"

import RequestPage from "../utils/page"
import Utils from "../utils/utils"

import LiefengModal from "@/components/LiefengModal"
import Map from "../../jujiabuild/Institutionalmanagement/childrens/map"

export default {
    components: { CompForm, CompLabel, ElemUpload, ElemCascader, ElemFormList, ElemFormItem, ElemMap, ElemUploads, LiefengModal, Map },

    data() {
        return {
            companyId: null,
            // 类型列表
            type_list: null,
            // 富文本组件 ID
            ueditor_id: this.$core.randomString(),
            // 是否开启线上服务
            online_service: "1",
            // 是否申请商家
            shopStatus: "1",
            // 菜单
            showMenu: "basicInfo",
            // 来源
            source: null,
            // 结算类型
            settlementTypes: null,

            // 地图模态框
            mapStatus: false,
            mapTip: false,
            showNum: 0,
            localName: "",
            center: {
                lat: "",
                lng: "",
            },
            clickCenter: {
                lat: "",
                lng: "",
            },
        }
    },

    async created() {
        sessionStorage.setItem("NODEENV", process.env.NODE_ENV)
    },

    methods: {
        // 关闭模态框
        mapFn(status) {
            if (!status && this.mapTip) {
                this.mapStatus = status
                this.mapTip = false
            } else {
                this.mapStatus = status
            }
        },
        // 选择经纬度确定按钮
        saveMap() {
            this.mapStatus = false
        },
        openMap() {
            this.mapStatus = true
        },
        async open(id) {
            this.companyId = id
            this.showMenu = "basicInfo"
            this.source = id ? "edit" : "create"

            if (!this.type_list) {
                this.type_list = await this.getAllType()
            }

            if (!this.settlementTypes) {
                this.getAllSettlementType()
            }

            this.$refs.comp_form.open(id)
        },

        onCloseForm() {
            document.getElementById(this.ueditor_id)?.contentWindow?.setContent("")
        },

        /**
         * 打开标签选择弹窗
         */
        onOpenSelectLabel() {
            this.$refs.comp_label.open(this.labelCodes)
        },

        /**
         * 处理数据
         */
        processData(data) {
            console.log("数据前置触发")
            var res = {}

            for (let i = 0, fs = data?.companyFileVos; i < fs?.length; i++) {
                const f = fs[i]

                if (!f || !f.fileUrl) {
                    continue
                }

                f.url = decodeURIComponent(f.fileUrl)

                switch (f.businessType) {
                    case "1": // 营业执照
                        res.business_license = f
                        break
                    case "2": // 封面图片
                        res.cover_image = f
                        break
                    case "5": // 手持身份证照
                        res.id_card_handheld = f
                        break
                    case "6": // 身份证正面
                        res.id_card_front = f
                        break
                    case "7": // 身份证反面
                        res.id_card_reverse = f
                        break
                    case "8": // 介绍图片
                        if (!res.introduction_image) {
                            res.introduction_image = []
                        }
                        res.introduction_image.push(f)
                    case "9": // 诚信经营承诺函/服务协议
                        res.letter_commitment = f
                        break
                    case "10": // 现场图片
                        if (!res.scene_photo) {
                            res.scene_photo = []
                        }
                        res.scene_photo.push(f)
                        break
                    case "11": // 视频
                        res.video = f
                        break
                    case "12": // 音频
                        res.audio = f
                        break
                }
            }

            // 营业时间
            res.businessTimeRos = data.businessTimeRos
                ? Utils.each(data.businessTimeRos, e => {
                      // 拆分周规则的数组
                      e.rule = e.rule.split(",")
                  })
                : []

            this.shopStatus = data.companyInfoRoV2.shopStatus

            // 支付平台商户ID
            res.merchantId = data.merchantId

            // 拷贝对象
            Utils.copyObjcetByKeys(res, data.companyInfoRoV2)
            Utils.copyObjcetByKeys(res, data.companyArchivesRo)

            // 写入富文本内容
            data.companyInfoRoV2.introduce &&
                new Headway(document.getElementById(this.ueditor_id).contentWindow, "setContent").on().then(f => {
                    f(data.companyInfoRoV2.introduce)
                })

            // 写入个人信息
            Utils.each(data.companyChargeVos, v => {
                if (v.chargeType == "2") {
                    Utils.copyObjcetByKeys(res, v, "mobile", "idNum")
                    // 生产管理人
                    res.principal = v.name
                }
            })

            // 标签
            const codes = [],
                ids = [],
                names = []

            // 处理标签数据
            Utils.each(data.labelVoList, v => {
                if (!v) return

                switch (v.labelType) {
                    case "1":
                        res.type = v.id
                        break
                    case "2":
                    case "3":
                        codes.push(v.labelCode)
                        ids.push(v.id)
                        names.push(v.labelName)
                        break
                }
            })

            // 标签
            this.labelCodes = codes
            res.companyLabels = ids
            res.companyLabelNames = names.join(", ")

            // 处理社区所属
            res.community = []

            Utils.each(["zoneCode", "streetCode", "communityCode"], k => {
                if (!data.companyInfoRoV2[k]) {
                    return "break"
                }
                res.community.push(data.companyInfoRoV2[k])
            })

            if (data.companyInfoRoV2.longitude && data.companyInfoRoV2.latitude && data.companyInfoRoV2.longitude != "" && data.companyInfoRoV2.latitude != "") {
                res.longitude = data.companyInfoRoV2.longitude + "," + data.companyInfoRoV2.latitude
            }
            return res
        },

        onSubmitLabel(evt) {
            const es = evt.value.entitys
            const ids = []
            const names = []
            const codes = []

            for (let i = 0; i < es.length; i++) {
                let v = es[i]

                ids.push(v.id)
                names.push(v.labelName)
                codes.push(v.labelCode)
            }

            this.labelCodes = codes
            this.$refs.comp_form.setValue({
                companyLabels: ids,
                companyLabelNames: names.join(", "),
            })
        },

        /**
         * 获取所有标签
         */
        getAllType() {
            return new Promise(resolve => {
                const page = new RequestPage(`/gateway/api/sycompany/pc/label/getLabelByPage?oemCode=${this.$core.getUserInfo().oemCode}&labelType=1`, {
                    onChange: res => {
                        if (page.isLoaded()) {
                            for (let i = 0; i < res.length; i++) {
                                const e = res[i]
                                e.key = e.labelCode
                            }

                            resolve(res)
                        } else {
                            page.load()
                        }
                    },
                })
            })
        },

        getAllSettlementType() {
            return this.$get("/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "BILLING_TYPE",
                appCode: "",
            }).then(res => {
                this.settlementTypes = res.dataList
            })
        },

        activeMenu(menu) {
            this.showMenu = menu
        },

        /**
         * 提交表单的前置事件
         */
        async onSubmitBefore(data) {
            const res = {
                oemCode: parent.vue.oemInfo.oemCode,
                companyId: this.companyId,
            }
            const iol = this.online_service === "1"

            var businessTimeRos = []

            // 营业时间
            Utils.each(data.businessTimeRos, v => {
                if (v.rule && v.rule.length > 0 && v.startTime && v.endTime) {
                    // 合并周规则的数组
                    var rule = v.rule instanceof Array ? v.rule.join(",") : v.rule
                    // 添加到数组
                    businessTimeRos.push({
                        ...v,
                        rule,
                    })
                }
            })

            res.businessTimeRos = businessTimeRos

            // 获取标签
            const cls = data.companyLabels || []
            // 是否存在类型标签
            if (data.type) {
                cls.push(data.type)
            }

            res.companyInfoRoV2 = {
                companyLabels: cls,
                startDate: data.startDate || new Date(),
                businessStatus: data.businessStatus || "2",
                shopStatus: this.shopStatus,
                remark: data.remark,
            }

            Utils.copyObjcetByKeys(
                res.companyInfoRoV2,
                data,
                "name",
                "addr",
                "nature",
                "zoneCode",
                "streetCode",
                "communityCode",
                "settlementType",
                "platformUseFee",
                "margin",
                "dividedScale",
                "status",
                "longitude",
                "latitude"
            )

            // 个人信息
            res.companyChargeVos = [
                {
                    chargeType: "2",
                    idNum: iol && this.shopStatus == "1" ? data.idNum : null,
                    mobile: data.mobile,
                    name: data.principal,
                },
            ]

            // 文件资源
            var fs = []

            // 封面图片
            if (data.cover_image) {
                fs.push({
                    businessType: 2,
                    fileId: data.cover_image.fileId,
                    fileUrl: data.cover_image.url,
                })
            }

            // 介绍图片，多图模式
            if (data.introduction_image) {
                data.introduction_image.forEach(v => {
                    fs.push({
                        businessType: 8,
                        fileId: v.fileId,
                        fileUrl: v.url,
                    })
                })
            }

            const image = await this.$refs.elem_upload_scene_photo.getFiles(),
                video = await this.$refs.elem_upload_video.getFiles(),
                audio = await this.$refs.elem_upload_audio.getFiles()

            if (image?.length > 0) {
                fs.push(
                    ...image.map(v => {
                        return {
                            fileUrl: v,
                            businessType: "10",
                        }
                    })
                )
            }

            if (video) {
                fs.push({
                    fileUrl: video,
                    businessType: "11",
                })
            }

            if (audio) {
                fs.push({
                    fileUrl: audio,
                    businessType: "12",
                })
            }

            // 是否开启线上模式
            if (iol) {
                // 拷贝对象值
                Utils.copyObjcetByKeys(res.companyInfoRoV2, data, "appointmentStatus", "shopStatus")
                res.companyInfoRoV2.shopStatus = this.shopStatus

                // 是否申请商家
                if (this.shopStatus == "1") {
                    // 支付平台商户ID
                    res.merchantId = data.merchantId

                    res.companyArchivesRo = {
                        email: data.email,
                    }

                    // 上传图片
                    data.business_license &&
                        fs.push({
                            businessType: 1,
                            fileId: data.business_license.fileId,
                            fileUrl: data.business_license.url,
                        })
                    data.id_card_handheld &&
                        fs.push({
                            businessType: 5,
                            fileId: data.id_card_handheld.fileId,
                            fileUrl: data.id_card_handheld.url,
                        })
                    data.id_card_front &&
                        fs.push({
                            businessType: 6,
                            fileId: data.id_card_front.fileId,
                            fileUrl: data.id_card_front.url,
                        })
                    data.id_card_reverse &&
                        fs.push({
                            businessType: 7,
                            fileId: data.id_card_reverse.fileId,
                            fileUrl: data.id_card_reverse.url,
                        })
                    data.letter_commitment &&
                        fs.push({
                            businessType: 9,
                            fileId: data.letter_commitment.fileId,
                            fileUrl: data.letter_commitment.url,
                        })

                    Utils.copyObjcetByKeys(res.companyArchivesRo, data, "bankAdminName", "bankAccount", "bankBranchName", "bankBranchNum")

                    // if (res.companyArchivesRo && Object.keys(res.companyArchivesRo)) {
                    // }
                }

                // 获取富文本简介内容
                res.companyInfoRoV2.introduce = document.getElementById(this.ueditor_id).contentWindow.getContent()
            }

            res.companyFileVos = fs

            if(data.longitude && data.longitude != '' && data.longitude.indexOf(',') > -1){
                
                let list = data.longitude.split(',')
                res.companyInfoRoV2.longitude = list[0]
                res.companyInfoRoV2.latitude = list[1]
            }
            return res
        },

        /**
         * 监听社区改变事件
         */
        onChangeCasader(evt) {
            Utils.each(evt, (v, i) => {
                this.$refs.comp_form.setValue(["zoneCode", "streetCode", "communityCode"][i], v)
            })
        },
    },
}
</script>

<style lang="less">
.jointly-comp-form {
    .content-box {
        padding: 0 !important;
        height: 100%;

        form {
            height: 100%;
        }
    }

    .jointly-info-form {
        .title-box {
            margin: 30px 0 10px 0;
            font-size: 22px;
            font-weight: bold;
            color: #2faaf7;
        }
    }

    .securityDiv {
        border-width: 0px;
        margin-left: 20px;
        width: 72px;
        height: 24px;
        display: flex;
        font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC", sans-serif;
        font-weight: 650;
        font-style: normal;
        color: #10bc92;
    }

    .container {
        display: flex;
        justify-content: space-between;
        height: 100% !important;

        /deep/ .menu {
            height: 100%;

            .ivu-menu {
                height: 100%;
                width: 220px !important;
            }
        }

        .content {
            overflow: auto;
            flex: 1;
            height: 100% !important;
            padding: 0 20px;
            padding-top: 0 !important;
        }
    }

    /deep/ .validate {
        &::before {
            content: "*";
            display: inline-block;
            margin-right: 4px;
            line-height: 1;
            font-family: SimSun;
            font-size: 14px;
            color: #ed4014;
        }
    }

    /deep/ #modal_contentarea {
        overflow: inherit !important;
    }
}
</style>
